:root {
  --nav-color: #1E2128; /* Replace with your desired color */
  --button: #436B95;
}



@tailwind base;
@tailwind components;
@tailwind utilities;
/* styles.css or your global styles file */
